import React from 'react';

import "./bat2.css";

const Bat = () => {
  return (
     <div className="wrapper-halloween">
      <div className="bat-overlay">
        <svg
          version="1.1"
          id="Lager_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 600 400"
          style={{ enableBackground: "new 0 0 600 400" }}
        >
          <g className="bat1">
            <path d="M306.9,145.4l-7.3,7.3l-7.3-7.3c-0.6-0.6-1.6-0.2-1.6,0.6v7.9h7.6h2.5h7.6V146C308.5,145.2,307.5,144.8,306.9,145.4z" />
            <path
              className="wing"
              d="M408.4,167.5c-3.3-2.7-7.6-4.4-12.2-4.4c-9.3,0-17.1,6.6-18.9,15.4c-2.8-1.6-6.1-2.5-9.6-2.5c-8.5,0-15.7,5.5-18.3,13.1
		c-3-6.6-9.7-11.3-17.5-11.3c-7.7,0-14.4,4.6-17.5,11.1c-6.9-4.7-11-10.7-11-17.1c0-2.4,0.6-4.7,1.6-6.9c0.2,0,0.4,0,0.7,0
		c24.7,0,45.4-9,51.2-21.1C383,144.1,404.5,154.3,408.4,167.5z"
            />

            <path
              className="wing1"
              d="M191.6,167.5c3.3-2.7,7.6-4.4,12.2-4.4c9.3,0,17.1,6.6,18.9,15.4c2.8-1.6,6.1-2.5,9.6-2.5c8.5,0,15.7,5.5,18.3,13.1
		c3-6.6,9.7-11.3,17.5-11.3c7.7,0,14.4,4.6,17.5,11.1c6.9-4.7,11-10.7,11-17.1c0-2.4-0.6-4.7-1.6-6.9c-0.2,0-0.4,0-0.7,0
		c-24.7,0-45.4-9-51.2-21.1C217,144.1,195.5,154.3,191.6,167.5z"
            />
            <path d="M312.3,158.2c0,5.2-6,27.3-12.9,27.3c-7,0-12.3-22.1-12.3-27.3s5.7-9.5,12.6-9.5C306.6,148.8,312.3,153,312.3,158.2z" />

            <ellipse className="eye1" cx="295.2" cy="161.8" rx="1.5" ry="3" />
            <ellipse className="eye1" cx="304.2" cy="161.8" rx="1.5" ry="3" />

            <g className="shadow1">
              <path
                className="wing1"
                d="M387.8,238.6c-2.7-2.2-6.1-3.5-9.9-3.5c-7.5,0-13.8,5.4-15.3,12.5c-2.3-1.3-4.9-2.1-7.8-2.1c-6.9,0-12.7,4.4-14.8,10.6
		c-2.5-5.4-7.9-9.1-14.2-9.1c-6.3,0-11.7,3.7-14.1,9c-5.6-3.8-8.9-8.6-8.9-13.8c0-1.9,0.5-3.8,1.3-5.6c0.2,0,0.4,0,0.6,0
		c20,0,36.7-7.3,41.4-17.1C367.3,219.6,384.7,227.8,387.8,238.6z"
              />
              <path
                className="wing2"
                d="M212.2,238.6c2.7-2.2,6.1-3.5,9.9-3.5c7.5,0,13.8,5.4,15.3,12.5c2.3-1.3,4.9-2.1,7.8-2.1c6.9,0,12.7,4.4,14.8,10.6
		c2.5-5.4,7.9-9.1,14.2-9.1c6.3,0,11.7,3.7,14.1,9c5.6-3.8,8.9-8.6,8.9-13.8c0-1.9-0.5-3.8-1.3-5.6c-0.2,0-0.4,0-0.6,0
		c-20,0-36.7-7.3-41.4-17.1C232.7,219.6,215.3,227.8,212.2,238.6z"
              />
              <ellipse cx="299" cy="242.2" rx="9.3" ry="17" />
            </g>
          </g>
        </svg>
      </div>
     </div>

     
  );
};

export default Bat;
