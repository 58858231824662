import React from 'react';
import '../App.css';
import './HeroSection.css';




function HeroSection(){
    return (
        <>
        <div className='hero-container'>
            <img className='wolf-image' src="/images/wolf_1.png" alt='wolf'/>
         </div>
         </>
    )
}

export default HeroSection;