import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';




function HeroAbout(){
    return (
        <div className='hero-container'>
    
            <img className='razn-hell' src="/images/razn_hell_full.jpg" alt='devil'/>
            {/* <img className='wolf-image' src="/images/wolf.png" alt='devil'/> */}
            
         </div>
    )
}

export default HeroAbout;