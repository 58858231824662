import React from 'react';
import '../../App.css'
import Social from '../Social';
import '../Social.css';
import HeroAbout from '../HeroAbout';




export default function Contact(){
    return (
        <>
    
       
       <div className='contact'>
       <h1 className='title-link'>
            hell_city_havoc_official
        </h1>
        <div>
        <Social />
        </div>
       
            
       </div>
        
      

        


        

    
        
        

        
        
        </>
        
    )
}